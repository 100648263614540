import React, { useState, FC, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

import "./Navbar.scss";

import { ReactComponent as CalendarLTRIcon } from "../../svg/calendar-ltr.svg";
import { ReactComponent as PeopleIcon } from "../../svg/people.svg";
import { ReactComponent as PersonIcon } from "../../svg/person.svg";
import { ReactComponent as TableBottomRow } from "../../svg/table-bottom-row.svg";
import { ReactComponent as TestIcon } from "../../svg/test.svg";
import { ReactComponent as Power } from "../../svg/power.svg";
import { ReactComponent as Money } from "../../svg/shopping-bag.svg";
import { ReactComponent as FriendIcon } from "../../svg/tour-money.svg";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useNavigate } from "react-router-dom";
import { UserSlice } from "src/service/reducers/UserSlice";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";

export const Navbar: FC<{ stateMobShow: boolean }> = (stateMobShow) => {
  const { signOutUser } = UserSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mobshow, setmobShow] = useState<boolean>(stateMobShow.stateMobShow);
  const { t } = useTranslation(namespaces.pages.hello);
  useEffect(() => {
    setmobShow(stateMobShow.stateMobShow);
  }, [stateMobShow]);
  const user = useAppSelector((store: any) => store.user.data);
  const users = {
    fullName: "",
    phone: "",
    postalCode: "",
    isStudent: true,
    personData: false,
    mailAdvertising: false,
    publicOffers: false,
  };

  const signOut = function () {
    dispatch(signOutUser(users));
    navigate("/login");
  };

  return (
    <div className={mobshow == true ? "navbar navbar--active" : "navbar"}>
      <div className="navbar__area">
        <Link to="/my-courses" className="logo">
          <img src="/assets/svg/EasyEnt.svg"/>
        </Link>
        <nav>
          {user?.is_parent && (
            <NavLink
              className={({ isActive }) => (isActive ? "active" : undefined)}
              to="/parent"
            >
              <CalendarLTRIcon />
              <p>{t("navbar.Мой_ребенок", { ns: namespaces.common })}</p>
            </NavLink>
          )}
          
          {!user?.is_parent && (
            <NavLink
              className={({ isActive }) => (isActive ? "active" : undefined)}
              to="/my-courses"
            >
              <TableBottomRow />
              <p>{t("navbar.Мои_курсы", { ns: namespaces.common })}</p>
            </NavLink>
          )}
          <NavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            to="/teachers"
          >
            <PeopleIcon />
            <p>{t("navbar.Преподаватели", { ns: namespaces.common })}</p>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            to="/my-profile"
          >
            <PersonIcon />
            <p>{t("header.Мой_профиль", { ns: namespaces.common })}</p>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            to="/subscriptions"
          >
            <Money />
            <p>{t("navbar.Мои_оплаты", { ns: namespaces.common })}</p>
          </NavLink>
          {!user?.is_parent && (
            <NavLink
              className={({ isActive }) => (isActive ? "active" : undefined)}
              to="/tests"
            >
              <TestIcon />
              <p>{t("navbar.Тесты_ЕНТ", { ns: namespaces.common })}</p>
            </NavLink>
          )}
          {!user?.is_parent && (
            <NavLink
              className={({ isActive }) => (isActive ? "active" : undefined)}
              to="/friend"
            >
              <FriendIcon />
              <p>Приведи друга</p>
            </NavLink>
          )}
          <span className="logout" onClick={signOut.bind(this)}>
            <Power />
            <p>{t("navbar.Выход", { ns: namespaces.common })}</p>
          </span>
        </nav>
      </div>
    </div>
  );
};
