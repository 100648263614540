import axios from "axios";
import { authHeader } from "src/utils/authHeader";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MyCourseItem } from "src/components";
import { ICourse } from "src/interface";

import "./MyCoursesPage.scss";

export const MyCourses = () => {
  document.title = "Мои Курсы | EasyENT";
  const [allCourses, setAllCourses] = useState<ICourse[]>([]);
  const [coursesType, setCourseType] = useState<boolean>(true);
  useEffect(() => {
    axios
      .get("/api/v1/courses/my/?skip=0&limit=100&active=" + coursesType,{ headers: authHeader()})
      .then((response) => setAllCourses(response.data));
  }, [coursesType]);
 
  const findExistCourse = allCourses;

  return (
    <div className="my-course">
      <h1 className="title">Мои курсы</h1>
      <ul className="mycourse-list">
        <li>
          <div className={coursesType == true ? "course-link course-link--active" : "course-link"} onClick={
            () => setCourseType(true)
          }>Курсы 2025</div>
        </li>
        <li>
          <div className={coursesType == false ? "course-link course-link--active" : "course-link"} onClick={
            () => setCourseType(false)
          }>Старые</div>
        </li>
        <li>
          <Link className="course-link" to="/buy">Покупка курсов</Link>
        </li>   
      </ul>
      <ul className="course-spisok">
        {findExistCourse.map((course) => (
          <li key={course.id}>
            <MyCourseItem course={course} showLessons={false}  />
          </li>
        ))}
      </ul>
    </div>
  );
};
