import axios from "axios";
import React, { useEffect, useState } from "react";
import { ICourse, ILesson,  QuestionType } from "src/interface";
import { useParams, Link } from "react-router-dom";

import "./TeacherHomeworkView.scss";
import { authHeader } from "src/utils/authHeader";

export const TeacherHomeworkView = () => {
  document.title = "Курсы | EasyENT";
  const params = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [lesson, setLesson] = useState<ILesson>();

  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.course_id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
      });
    axios
      .get("/api/v1/lessons/" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setLesson(response.data);
      });
    // axios
    //   .get("/api/v1/question/?lesson_id=" + params.id, {
    //     headers: authHeader(),
    //   })
    //   .then((response) => {
    //     setQuestions(response.data);
    //   });
  }, []);

  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/teacher">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{course ? course.name : "загрузка"}</span>
        </li>
      </ul>
      <h1 className="title">{course ? course.name : "загрузка"}</h1>
      <div className="box">
        <div className="teacherhw-top">
          <h1 className="teacherhw-top__heading">Домашнее задание</h1>
        </div>
        <ul className="q-teacher">
          {lesson && lesson.questions?.map((question: any, index: any) => (
            <li key={question.id}>
              <div className="question-admin">
                <div className="question-admin__heading">
                  <div className="question-admin__text">
                    <div>
                      {index + 1 + ")"} {question.display_name}
                    </div>
                  </div>
                  {question.image !== null ? (
                    <div className="realadmin-choice-row__img">
                      <img
                        src={"https://app.easyent.kz" + question.image}
                      ></img>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {question["question_type"] &&
                question["question_type"] == QuestionType.choice ? (
                  <div className="question-row__area">
                    {question["question_choise"] !== undefined &&
                    question["question_choise"]["values"] !== undefined &&
                    question["question_choise"]["values"].length > 0 ? (
                      question["question_choise"]["values"]
                        .sort((a: any, b: any) => a?.seq_idx - b?.seq_idx)
                        .map((choice: any) => (
                          <div key={choice.id} className="admin-choice-row">
                            <div className="admin-choice">
                              {question.answer == choice.value ? (
                                <div className="tipo-input">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                              ) : (
                                <div
                                  data-sequence={index + 1}
                                  data-value={choice.value}
                                  data-quesiton-id={"" + question.id}
                                  className="tipo-input"
                                ></div>
                              )}
                              <div className="admin-choise__right">
                                <label htmlFor={"" + choice.id}>
                                  {choice.value}
                                </label>
                              </div>
                            </div>
                            {choice.image !== null ? (
                              <div className="adminreal-choice-row__img">
                                <img
                                  src={"https://app.easyent.kz/" + choice.image}
                                ></img>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                    ) : (
                      <div>пусто</div>
                    )}
                  </div>
                ) : (
                  <div className="task-area">
                    {/* <div className="task-area__input">
                      <input type="file" name={"file"+question.id} id={"file"+question.id} onChange={UploadPhoto} accept="image/png, image/jpeg"></input>
                    </div>
                    <textarea placeholder="Написать ответ"  className="task-area__textarea"></textarea> */}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
