import React, { useEffect } from "react";
import { Route, Routes, useNavigate} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getMe } from "src/service/asyncThunk";
import { UserSlice } from "src/service/reducers/UserSlice";
import { useLocation } from "react-router-dom";
import "./App.scss";
import {
  LoginPage,
  AuthPage,
  PrivacyPage,
  TermsPage,
  RegisterPage,
} from "./pages";
import { StudentLayout } from "./Layouts/StudentLayout";
import { AdminLayout } from "./Layouts/AdminLayout";
import { TeacherLayout } from "./Layouts/TeacherLayout";
import {
  // CalenderPage,
  CoursesStayStudy,
  MyCourses,
  MyProfilePage,
  NotFound,
  SubscriptionsPage,
  TransactionSuccessPage,
  TeachersPage,
  TeacherPage,
  ViewCoursePage,
  LessonVideoPage,
  HomeWorkPage,
  BuyCoursesPage,
  StaticCoins,
  TestsPage,
  TestsEntPage,
  ParentPage,
  ParentChildCourse,
  BestStudent,
  TournamentPage,
  Friend
} from "./pages";

import { ApprovalPage, RegNotificationPage } from "./pages_public";

import {
  AdminCoursesPage,
  AdminUsersPage,
  AdminTeacherPage,
  AdminOrdersPage,
  AdminEditUser,
  AdminEditCourse,
  AdminEditHomework,
  AdminUpdateHomework,
  AdminTestsPage,
  AdminTestInner,
  AdminTestAdd,
  AdminUserPerfomance,
  AdminHomeworksByLessons,
  AdminUsersByCourse,
  AdminHomeworksOfStudent,
  AdminCoins,
  AdminOrderInner,
  AdminViewVideo,
} from "./pages_admin";

import {
  TeacherСoursesPage,
  TeacherHomeworkPage,
  TeacherHomeworkSubjectPage,
  TeacherHomeworkView,
  TeacherСourseView,
  HomeworksByLessons,
  HomeworksOfStudent,
  AddHomework,
  UpdateHomework,
  UsersByCourse,
  UserActivity,
  TeacherUsers,
  TeacherProfile,
  TeacherTestsPage,
  TeacherTestInner,
} from "./pages_teacher";

const App = () => {
  
  const isLoggedIn = useAppSelector((store) => store.user.loggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      localStorage.removeItem("user");
      navigate("/login");
    }
  }, []);

  const user = useAppSelector((store) => store.user.data);
  const dispatch = useAppDispatch();
  const { updateUser } = UserSlice.actions;
  const [newuser, setnewUser] = React.useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get("refferal");

    if (referral) {
      localStorage.setItem("referal", referral);
      console.log(`Referral code ${referral} saved to localStorage`);
    }
  }, [location.search]);
  
useEffect(() => {
  const fetchUserData = async () => {
    try {
      const res = await dispatch(getMe());
      if (res.payload) {
        dispatch(updateUser(res.payload));
        setnewUser(res.payload);
        if (res.payload.is_parent) {
          navigate("/parent");
        }
      } else {
        localStorage.removeItem("user");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      localStorage.removeItem("user");
      window.location.reload();
    }
  };

  // Call the function once on component mount
  fetchUserData();
}, []); 

  return isLoggedIn && newuser ? (
    <div className="app-wrapper">
      <Routes>
        <Route path="/" element={<StudentLayout user={user} />}>
          {/* <Route path="" element={<CalenderPage />} /> */}
          <Route path="my-courses" element={<MyCourses />} />
          <Route path="parent" element={<ParentPage />} />
          <Route path="parent/child-course" element={<ParentChildCourse />} />
          {/* <Route path="calendar" element={<CalenderPage />} /> */}
          <Route path="friend" element={<Friend />} /> 
          <Route path="tournament" element={<TournamentPage />} />
          <Route path="best-student" element={<BestStudent />} />
          <Route path="teachers" element={<TeachersPage />} />
          <Route path="teacher_:id" element={<TeacherPage />} />
          <Route path="my-profile" element={<MyProfilePage />} />
          <Route path="course_:id" element={<ViewCoursePage />} />
          <Route path="course_:id/lesson_video_:id" element={<LessonVideoPage />}/>
          <Route path="course_:id/homework_:id" element={<HomeWorkPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="subscriptions" element={<SubscriptionsPage />} />
          <Route path="transaction/success" element={<TransactionSuccessPage />} />
          <Route path="courses" element={<CoursesStayStudy />} />
          <Route path="buy" element={<BuyCoursesPage />} />
          <Route path="about-coins" element={<StaticCoins />} />
          <Route path="tests" element={<TestsPage />} />
          <Route path="tests/ent" element={<TestsEntPage />} />
        </Route>
        <Route path="/admin" element={<AdminLayout user={user} />}>
          <Route path="users" element={<AdminUsersPage />} />
          <Route path="courses" element={<AdminCoursesPage />} />
          <Route path="courses/course/:id" element={<AdminEditCourse />} />
          <Route path="courses/course/:course_id/users_by_course/:id" element={<AdminUsersByCourse />}/>
          <Route path="courses/course/:course_id/homeworks_by_lesson/:id" element={<AdminHomeworksByLessons />}/>
          <Route path="courses/course/:course_id/homeworks_by_lesson/:id/:user_id" element={<AdminHomeworksOfStudent />}/>
          <Route path="homework/:lesson_id" element={<AdminEditHomework />} />
          <Route path="update/:lesson_id" element={<AdminUpdateHomework />} />
          <Route path="view/:lesson_id" element={<AdminViewVideo />} />
          <Route path="users/edit/:id" element={<AdminEditUser />} />
          <Route path="users/perfomance/:id" element={<AdminUserPerfomance />}/>
          <Route path="teachers" element={<AdminTeacherPage />} />
          <Route path="orders" element={<AdminOrdersPage />} />
          <Route path="orders/edit/:id" element={<AdminOrderInner />} />
          <Route path="tests" element={<AdminTestsPage />} />
          <Route path="tests/:id" element={<AdminTestInner />} />
          <Route path="tests/add" element={<AdminTestAdd />} />
          <Route path="coins" element={<AdminCoins />} />
        </Route>
        <Route path="/teacher" element={<TeacherLayout user={user} />}>
          <Route path="" element={<TeacherСoursesPage />} />
          <Route path="homework/:lesson_id" element={<AddHomework />} />
          <Route path="update/:lesson_id" element={<UpdateHomework />} />
          <Route path="profile" element={<TeacherProfile />} />
          <Route path="course_:id" element={<TeacherСourseView />} />
          <Route path="course_:id/users_by_course/:id" element={<UsersByCourse />}/>
          <Route path="course_:course_id/homeworks_by_lesson/:id" element={<HomeworksByLessons />}/>
          <Route path="course_:course_id/homeworks_by_lesson/:id/:user_id" element={<HomeworksOfStudent />}/>
          <Route path="course_:course_id/material/:id" element={<TeacherHomeworkView />}/>
          <Route path="homeworks" element={<TeacherHomeworkPage />} />
          <Route path="homeworks/course_:id" element={<TeacherHomeworkSubjectPage />}/>
          <Route path="activity/course_id=:course_id&user_id=:id" element={<UserActivity />}/>
          <Route path="users" element={<TeacherUsers />} />
          <Route path="tests" element={<TeacherTestsPage />} />
          <Route path="tests/:id" element={<TeacherTestInner />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth/:provider" element={<AuthPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/verify" element={<ApprovalPage />} />
        <Route path="/notification" element={<RegNotificationPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  ) : (
    <></>
  );
};
export default App;
