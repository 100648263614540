import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { MyCourseItem } from "src/components";
import { IPenalty, ICourse,  ILesson, User_feeds } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { MyLessonItem } from "src/components";
import { Tabs } from "react-simple-tabs-component";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";
import { useAppSelector } from "src/hooks/redux";
import { Penalty } from "src/components";
import "./ViewCoursePage.scss";

export const ViewCoursePage = () => {
  const params = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [penalties, setPenalties] = useState<IPenalty[]>([]);
  const [lessonCompleted, setLessonCompleted] = useState<any>([]);
  const [User_feeds, setUser_feeds] = useState<User_feeds[]>([]);
  const user = useAppSelector((store) => store.user.data);
  const { t } = useTranslation(namespaces.pages.hello);
  function getCompletedLessons() {
    axios
      .get(`api/v1/courses/${params?.id}/lesson_completed_count`, {
        headers: authHeader(),
      })
      .then((res: any) => setLessonCompleted(res?.data));
  }

  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
    });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-09-09&to_date=2024-10-09&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setLessons(response.data);
      });
      axios
        .get("/api/v1/penalty/?skip=0&user_id=" + user.id + "&course_id=" +
            params.id, { headers: authHeader() })
        .then((response) => {
          setPenalties(response.data);
      });
    axios
      .get(
        "/api/v1/user_feed/?skip=0&user_id=" +
          user.id +
          "&course_id=" +
          params.id,
        { headers: authHeader() }
      )
      .then((response) => {
        setUser_feeds(response.data);
      });
    getCompletedLessons();
  }, []);
  const [oldLessons, setOldLessons] = useState<ILesson[]>([]);
  useEffect(() => {
    axios
    .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2022-09-09&to_date=2024-10-09&skip=0&limit=100", {
      headers: authHeader(),
    })
    .then((response) => {
      setOldLessons(response.data);
    });
  }, []);
  
  const TabSeptember = () => {
    return (
      <>
        <div className="course-penalty course-penalty--mini">
          {<Penalty package_feed={User_feeds.length ? User_feeds[0]['package'] : "standart"} penalties={penalties}/>}
        </div>
        {User_feeds.filter((e) => e.start_date === "2024-09-09").length > 0
          ? lessons.map((lesson, index) =>
              lesson.start_date ? (
                  <MyLessonItem
                    lesson={lesson}
                    index={index}
                    without_penalty={course ? course.without_penalty : false}
                    key={lesson.id}
                    completed={lessonCompleted}
                  />
              ) : (
                ""
              )
            )
          : "Доступов за сентябрь нету!!!"}
      </>
    );
  };
  const Tabfree = () => {
    return (
      <>
        {oldLessons.map.length > 0
          ? oldLessons.map((lesson, index) =>
              lesson.start_date ? (
                  <MyLessonItem
                    lesson={lesson}
                    index={index}
                    key={lesson.id}
                    without_penalty={course ? course.without_penalty : false}
                    completed={lessonCompleted}
                  />
              ) : (
                ""
              )
            )
          : "Уроки еще не загружены"}
      </>
    );
  };
  const tabs1 = [
    {
      label: "9 Cен - 9 Окт",
      Component: TabSeptember,
    },
  ];
  const emptytabs = [
    {
      label: "Уроки",
      Component: Tabfree,
    },
  ];

  return (
    <div className="view-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/my-courses">{t("navbar.Мои_курсы", { ns: namespaces.common })}</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{course ? course.name : ""}</span>
        </li>
      </ul>
      {course ? (
        <MyCourseItem key={params.id} showLessons={true} course={course} />
      ) : (
        ""
      )}
      <div className="last-lesson">
        {course ? (
            <>
              <Tabs tabs={course.price === 10 ? 
                tabs1 : emptytabs
                } /> 
            </>
        ) : (
          <>
            Уроков нету
          </>
        )}
      </div>
    </div>
  );
};
