import React, { FC} from "react";
import { IPenalty } from "src/interface";
import "./Penalty.scss";

function calculateHeartLevels(package_feed:any, penalty_count: number) {
    // Initialize an array with heart levels
    let heartLevels = [1, 1, 1];
    if(package_feed == 'premium'){
      heartLevels = [1, 1, 1, 1, 1];
    }
    const reduceByNumber = heartLevels.length - penalty_count;
    heartLevels.splice(reduceByNumber);
    return heartLevels;
  }

export const Penalty: FC<{ package_feed:string | undefined, penalties: IPenalty[] }> = ({
    penalties, package_feed
}) => {
    return (<>
        <div>Система жизни</div>
        {calculateHeartLevels(package_feed, penalties.length).reverse().map((j) => <img key={j} src={`/assets/courses/heart-1.png`} width="25px"/>)}
        </>)
  };