import axios from "axios";
import React, { useEffect, useState } from "react";
import { AdminUser } from "src/interface";
import { Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";
import "./AdminUsersPage.scss";

export const AdminUsersPage = () => {
    document.title = "Пользователи Admin | EasyENT";
    const [users, setAllUsers] = useState<AdminUser[]>([]);
    const [limit, ] = useState(300);
    const [page, setPage] = useState(1); 
    const [totalUsers, ] = useState(5000);
    const [search_text, setSearch_text] = useState<string>();
    const [search_email, setSearch_email] = useState<string>();
    useEffect(() => {
      const skip = (page - 1) * limit;
      axios
        .get("/api/v1/users/?skip=" + skip + "&limit=" + limit,{ headers: authHeader()})
        .then((response:any) => setAllUsers(response.data));
    }, [page, limit]);
    const totalPages = Math.ceil(totalUsers / limit);

    const handleNextPage = () => {
      if (page < totalPages) {
        setPage((prevPage) => prevPage + 1);
      }
    };
    const handlePrevPage = () => {
      if (page > 1) {
        setPage((prevPage) => prevPage - 1);
      }
    };

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
      const element = e.target as HTMLInputElement;
      setSearch_text(element.value);
      axios
        .get("/api/v1/users/?full_name=" + search_text,{ headers: authHeader()})
        .then((response:any) => setAllUsers(response.data))
    }
    const onChangeSearchEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
      const element = e.target as HTMLInputElement;
      setSearch_email(element.value);
      axios
        .get("/api/v1/users/?email=" + search_email,{ headers: authHeader()})
        .then((response:any) => setAllUsers(response.data))
    }  
    const showYearStudent = () => {
      axios
        .get("/api/v1/users/?skip=0&limit=500&exist_feed_for_month=2024-09-09",{ headers: authHeader()})
        .then((response:any) => setAllUsers(response.data))
    }    

  return (
    <div>
      <h1 className="title">Пользователи </h1> 
      <div className="white-row box">
          <div className="white-div">
            <span>ФИО:</span>
            <input className="white-div__input" onChange={onChangeSearchText} value={search_text} type="text"></input>
          </div>
          <div className="white-div">
            <span>Почта:</span>
            <input className="white-div__input" onChange={onChangeSearchEmail} value={search_email} type="text"></input>
          </div>
          <div className="white-div">
            <button onClick={showYearStudent}>Годовые</button>
          </div>
      </div>
      <table className="table">
        <tbody> 
        <tr>
          <th style={{width: '40px'}}>
            Id dss
          </th>
          <th style={{width: '220px'}}>
            Фио
          </th>          
          <th style={{width: '180px'}}>
            Почта
          </th>
          <th style={{width: '140px'}}>
            Номер телефона
          </th>
          <th style={{width: '80px'}}>
            Язык
          </th>
          <th style={{width: '90px'}}>
            Подписка
          </th>
          <th style={{width: '130px'}}>
            Управление
          </th>
        </tr>
        {users.map((user) => (
          <tr key={user.id}>
            <td style={{width: '40px'}}>
              {user.id}
            </td>
            <td style={{width: '220px'}}>
              {user.full_name}
            </td>
            <td style={{width: '180px'}}>
              {user.email}
            </td>
            <td style={{width: '140px'}}>
              {user.phone}
            </td>
            <td style={{width: '100px'}}>
              {user.lang}
            </td>
            <td style={{width: '90px'}}>
              {user.status_student ? user.status_student == true ?  "Ученик" : "Отказался от курсов" : "Пользователь"}
            </td>
            <td style={{width: '130px'}}>
              <div className="item-control">
                <Link to={`edit/${user.id}`} className="item-control__item">
                  <i className="fa-solid fa-pen"></i>
                </Link>
              </div>
            </td>
          </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button onClick={handlePrevPage} disabled={page === 1}>
          Предыдущая
        </button>
        <span>{page} из {totalPages}</span>
        <button onClick={handleNextPage} disabled={page === totalPages}>
          Следующая
        </button>
      </div>
    </div>
  );
};